<template lang="html">
    <div class="ps-blog--post-items">
        <template v-if="postItems">
            <div class="row">
                <template v-if="column === 4">
                    <div
                        class="col-lg-3 col-md-4 col-sm-6 col-12"
                        v-for="item in postItems"
                        :key="item.title[0]"
                    >
                        <article-grid :post="item" />
                    </div>
                </template>
                <template v-else-if="column === 2">
                    <div class="col-2 col-sm-6 col-12" v-for="item in postItems" :key="item.id">
                        <article-grid :post="item" />
                    </div>
                </template>
                <template v-else>
                    <div class="col-md-4 col-sm-6 col-12" v-for="item in postItems" :key="item.id">
                        <article-grid :post="item" />
                    </div>
                </template>
            </div>
        </template>
        <p v-else>No post found.</p>
    </div>
</template>

<script>
	import axios from 'axios';
	import ArticleGrid from '@/components/elements/posts/ArticleGrid';

	export default {
		name: 'ModuleBlogPostGrids',
		components: { ArticleGrid },
		props: {
			collectionSlug: {
				type: String,
				default: ''
			},
			column: {
				type: Number,
				default: () => 3
			}
		},
		computed: {
			category () {
				return this.$route.params.category !== undefined ? this.$route.params.category : false;
			}
		},
		data() {
			return {
				postItems: null
			};
		},
		methods: {
			async fetch() {
				let posts;
				this.$router.onReady(async () => {
					if (this.category) {
						posts = await axios.get('https://livenewsapi.com/feed?source=blend&category=' + this.category);
					} else {
						posts = await axios.get('https://livenewsapi.com/feed?source=blend&country=ca');
					}
					this.postItems = posts.data;
				});
			}
		},
		mounted () {
			this.fetch();
		},
		watch: {
			category () {
				this.fetch();
			}
		}
	};
</script>
